@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
  background-color: #ffffff;
}

html, body {
  height: 100%; /* Ensure html and body take up the full height of the viewport */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #ffffff; /* Light blue background color */
}

.passion-project-text {
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */
  color: #538cc6; /* Set the color */
  text-align: center; /* Center the text */
  font-size: 20px; /* Increase font size */
  font-weight: 700; /* Make the text thicker */
  line-height: 1.3; /* Adjust line height for better readability */
  margin-bottom: 40px; 
}

/* Add a link to include the Poppins font if you're using Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');



/* Navigation bar styles */
.navbar {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa; /* Adjust background color as needed */
}

.navbar a {
  text-decoration: none;
}

.logo {
  height: 50px; /* Adjust height as needed */
  margin-right: 20px; /* Space between logo and buttons */
}

.navbar button {
  margin-right: 10px;
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */
  background-color: #f7f7fb; /* Button background color */
  color: #93bae9;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.navbar button:hover {
  background-color: #f7f7fb; /* Lavender purple background on hover */
  color: rgb(107, 131, 227); /* White text on hover */
}

/* Adjustments for the "Projects" title */
.projects-title {
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */
  color: #93bcdc; /* Set the color to #524D4B */
  text-align: center; /* Center the text */
  font-size: 104px; /* Set the font size to 104 pixels */
  margin-top: 0px; /* Add top margin to move it lower */
  padding-top: 0px; /* Optional: add padding if needed */
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}


/* Input fields */
.input-field {
  margin-bottom: 15px;
}

/* Autocomplete dropdowns */
.autocomplete {
  margin-bottom: 15px;
}

/* Add Project Button */
.add-project-button {
  margin-bottom: 20px;
  background-color: #a2d9d5; /* Adjusted color */
  color: white; /* Text color for the button */
  align: center; /* Center the text */
  border: none;
  border-radius: 10px;
  padding: 10px 10px;
  
}

.add-project-button:hover {
  cursor: pointer;
  background-color: #a2d9d5; /* Slightly darker adjusted color */
}

.separator {
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

/* Project card title with blue color */
.proj-card-title {
  font-size: 25px; /* Font size for the title */
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */
  color: #3c71c0; /* Blue color for the title */
  margin-bottom: -10px; /* Space below the title */
}

/* Project card description */
.proj-card-desc {
  color: #333333; /* Dark text color */
  font-size: 16px; /* Font size */
  margin-bottom: 10px; /* Space below the description */
}

/* Contact information with light blue color */
.proj-card-contact {
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */

  color: rgb(103, 144, 248); /* Light blue color */
  font-size: 14px; /* Font size for contact information */
}

/* previous File for 501c3 button styles, now .counseling service button */
.counseling-service-button button {
  background-color: #f7f7fb; /* White background */
  color: #93bae9;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
}

.counseling-service-button button:hover {
  background-color: #f7f7fb; /* Lavender purple background on hover */
  color: rgb(107, 131, 227); /* White text on hover */
}

/* Container for the entire content */
.main-container {
  min-height: 100vh; /* At least full viewport height */
  padding: 20px; /* Padding to prevent sticking to edges */
  background-color: inherit; /* Inherit the background color from body */
}

/* Adjust the container to fit the cards dynamically */
.proj-card-container {
  display: grid; /* Use CSS Grid for layout */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Flexible columns */
  grid-row-gap: 20px; /* Consistent space between rows */
  grid-column-gap: 20px; /* Consistent space between columns */
  padding: 20px; /* Padding for the container */
}

/* Hover effect to enhance floating appearance */
.proj-card-container {
  display: grid; /* Use CSS Grid */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible grid with auto-fit columns */
  grid-row-gap: 20px; /* 20px vertical space between rows */
  grid-column-gap: 20px; /* Consistent space between columns */
  padding: 20px; /* Padding around the grid */
  justify-content: center; /* Center the grid content */
}

/* Project card style */
.proj-card {
  background-color: white; /* White background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transitions */
  padding: 20px; /* Padding for content */
  text-align: center; /* Center the text */
  display: flex; /* Flex layout for vertical alignment */
  flex-direction: column; /* Align content vertically */
  justify-content: space-between; /* Evenly distribute content */
}

/* #HOME PAGE IMAGE */
/* Ensure Poppins font is imported */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');


.advice-page {
  background-image: url('./images/adviceproj.png'); /* Set the background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  height: 100vh; /* Set the height of the container */
  padding: 20px; /* Add padding if needed */
  /* Add any additional styles as needed */
}

.edit-button {
  font-size: 12px; /* Adjust the font size as needed */
  flex: 0 0 auto; 
}

/* Centering the button container */
.button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
}

/* Styles for the Project Hub button */
.project-hub-button {
  padding: 10px 20px; /* Padding for the button */
  font-family: 'Poppins', sans-serif;
  font-size: 20px; /* Font size for the button */
  background-color: rgb(124, 177, 242); /* White background on hover */
  color: #0341a3; /* Lavender purple text on hover */
  border: 1px solid #f8f7f9; /* Border on hover */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center text inside button */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  margin-top: -670px; /* Move the button up */
}

/* Section Heading Styles */
.section-heading {
  font-size: 24px;
  color: #6e9f9e; /* Default color for headings */
  margin-bottom: 15px;
}
.project-hub-button:hover {
  background-color: rgb(179, 207, 243);
  font-family: 'Poppins', sans-serif;
  color: #0341a3; /* Lavender purple text on hover */
  border: 1px solid #f5f3f7; /* Border on hover */
}

/* Color Sections */
.color-section {
  padding: 50px;
  color: #68719f;
  margin: 200px 0;
  border-radius: 100px;
}

.color-section:nth-of-type(odd) {
  background-color: #ffffff; /* Default pinkish color */
  margin-top: 25px; /* Move the button up */
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */
  
}

.color-section:nth-of-type(even) {
  background-color: #ffffff; /* Default teal color */
  margin-top: -600px; /* Move the button up */
  font-family: 'Poppins', sans-serif; /* Use a rounded, thicker font */
}

/* Footer */
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f2f5; /* Default dark blue */
  color: #000000;
  font-size: 14px;
}

.footer img {
  height: 70px;
}

.footer a {
  color: #81baaf;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .social-media-item {
  display: flex;
}

.footer .social-media-item a {
  margin: 0 20px;
}

.footer .social-media-item img {
  height: 30px;
}


.hero-heading {
  font-family: 'Poppins', sans-serif; /* Use Poppins font */
  font-size: 48px; /* Large font size */
  color: #65bcff; /* Blue color */
  margin: 40px 0; /* Margin for spacing */
  display: none; /* Hide by default */
}

.hero-heading.show {
  display: block; /* Show only when needed */
}

.animated-highlight {
  position: relative;
  display: inline-block;
  color: #5fb5a5; /* Tomato color for emphasis */
  font-weight: bold; /* Make text bold */
}

.animated-highlight::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* Position underline */
  width: 100%;
  height: 2px; /* Thickness of the underline */
  background-color: #5fb5a5; /* Same as text color */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.5s ease;
}

.animate-highlight::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.fieldFilter {
  margin: 20px 0;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.nav-buttons {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 20px; /* Space between buttons */
  width: 100%; /* Make the container take up the full width */
}

.dropdown-content a {
  font-family: 'Poppins', sans-serif; /* Use Poppins font */
  text-decoration: none;
  color: #313460; /* Initial color */
  display: block;
  margin-bottom: 10px;
  transition: color 0.3s; /* Smooth color transition */
}

.dropdown-content a:hover {
  color: #1E90FF; /* Blue color on hover */
}
